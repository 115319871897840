import React, { useRef, useState, useEffect, Suspense } from 'react'
import { Canvas, useFrame } from 'react-three-fiber'
import SplashOverlay from './components/SplashOverlay'
import Effects from './components/Effects'
import { Camera } from './components/Camera'
import Objects from './components/Objects'
import DeviceDetect from './components/DeviceDetect'
import Buildings from './components/Buildings'
import Lights from './components/Lights'

import './App.scss'

export default function App() {

  const [cruiseMode, setCruiseMode] = useState(false)
  const [showControls, setShowControls] = useState(true)

  const mphRef = useRef()
  const rpmRef = useRef()
  const joystickBaseRef = useRef()
  const joystickRef = useRef()
  const isMobile = DeviceDetect()
  const radioRef = useRef()

  let speed = 0
  let joystickAngle = false
  let joystickActive = false

  const wRef = useRef(),
        aRef = useRef(),
        sRef = useRef(),
        dRef = useRef()


  useEffect(() => {
    // console.log('use effect triggered')
    // window.addEventListener('resize', () => console.log(window.innerWidth, window.innerHeight))

    // console.log(cruiseMode)
    // console.log(radioRef.current && radioRef.current.dataset.currentTrack)
    // if (cruiseMode && radioRef.current.dataset.currentTrack == '0') {
    //   // console.log('play audio')

    //   // autoplay first track on start
    //   radioRef.current.querySelector('button[data-track="1"]').click()
    // }
  })

  const getSpeedHandler = () => {
    return speed
  }

  const getPressedKey = (key, isBeingPressed) => {

    if (!isMobile && cruiseMode) {
      let currentKey = false
      
      switch(key) {
        case "w":
          currentKey = wRef
          break
        case "a":
          currentKey = aRef
          break
        case "s":
          currentKey = sRef
          break
        case "d":
          currentKey = dRef
          break
        default:
          break
      }

      if (!isMobile && currentKey) {
        const currentClass = currentKey.current.className

        if (!currentClass.includes('active') && isBeingPressed)
          currentKey.current.className += ' active'
        else if (currentClass.includes('active') && !isBeingPressed)
          currentKey.current.className = currentClass.split(' active')[0]
      }
    }
  }

  const cruiseModeHandler = () => {
    setCruiseMode(true)
  }

  const KeyboardOverlay = (props) => {

    useEffect( () => {
      if (showControls === false)
        radioRef.current.querySelector('button[data-track="1"]').click()
    })

    const showControlsHandler = () => {
      setShowControls( prev => !prev)
    }

    return (
      <section className={`keyboard-overlay ${props.showControls ? '' : 'hidden'}`}>
        <h2>HOW TO PLAY <br /> <span>KEYBOARD CONTROLS</span></h2>
        <div className="content">
          <div className="container">
            <button ref={wRef} className="forward" title="Forward">W</button>
            <button ref={sRef} className="reverse" title="Reverse / Brake">S</button>
            <button ref={aRef} className="left" title="Turn Left">A</button>
            <button ref={dRef} className="right" title="Turn Right">D</button>
          </div>
        </div>
        <button className="button" onClick={showControlsHandler}><span>READY</span></button>
      </section>
    )
  }

  function CameraControl(props) {

    let defaultCarType = 2,
        carType = 3,
        goDirection = false,
        turnRight = false,
        turnLeft = false,
        turnRestrict = false,
        turnRate = 0.0008,
        turnRadiusMax = 0.02,
        turnReleaseDampen = 0.0015,
        speedDirection = 0.0,
        thrust = 0,
        rotation = 0,
        lastDirection = false,
        accelerationRate = 1,
        decelerationRate = 0.3,
        brakePower = 1.5,
        maxSpeed = 30,
        maxReverseSpeed = 15,
        maxThrust = 100,
        thrustAccelerationRate = accelerationRate * 2,
        thrustDecelerationRate = 1,
        perceptualSpeedMultiplier = 2

    const setCarType = (type) => {
      // console.log('cartype: ', type)

      switch(type) {
        case 1:
          carType = 1
          turnRate = 0.0008
          turnRadiusMax = 0.02
          turnReleaseDampen = 0.0045
          maxSpeed = 100
          maxThrust = 100
          accelerationRate = 1
          thrustAccelerationRate = accelerationRate * 2
          brakePower = 1.4
          break;
        case 2:
          carType = 2
          turnRate = 0.0016
          turnRadiusMax = 0.03
          turnReleaseDampen = 0.0030
          maxSpeed = 60
          maxThrust = 70
          accelerationRate = 0.6
          thrustAccelerationRate = accelerationRate * 2
          brakePower = 1
          break;
        case 3:
          carType = 3
          turnRate = 0.0010
          turnRadiusMax = 0.02
          turnReleaseDampen = 0.0015
          maxSpeed = 30
          maxThrust = 40
          accelerationRate = 0.3
          thrustAccelerationRate = accelerationRate * 2
          brakePower = 0.8
          break;
        default:
          break;
      }
    }

    setCarType(carType)
    
    // Listeners for Keyboard WASD Controls
    document.addEventListener('keydown', function(e){
      if (!isMobile && props.cruiseMode) {
        moveAction(e.key, true)
        props.getPressedKey(e.key, true)
      }
    })
  
    document.addEventListener('keyup', function(e){
      if (!isMobile && props.cruiseMode) {
        moveAction(e.key, false)
        props.getPressedKey(e.key, false)
      }  
    })
  
    const getSpeedAndRotation = () => {
  
      /* **************************************
      Event-Loop function that calculates speed 
      (accel/decel) based off keyboard input listeners
      *****************************************/
  
      let thrustStatus
  
      // if intentially accelerating/decelerating
      if (goDirection) {
  
        lastDirection = goDirection
        speedDirection = getAccelerationSpeed(speedDirection, goDirection)
        thrustStatus = 'accelerating'
        thrust = thrust < maxThrust ? thrust + thrustAccelerationRate : maxThrust
  
      // automatic deceleration
      } else {
      
        speedDirection = getDecelerationSpeed(speedDirection)
        if (speedDirection === 0) rotation = 0
        thrustStatus = 'decelerating'
        thrust = thrust > 0 ? thrust - thrustDecelerationRate : 0
  
      } 

      // update odometer
      const momentumStatus = (speedDirection && `${thrustStatus} ${lastDirection}`) || 'Idle'

      if (speedDirection !== 0 || thrust !== 0 || momentumStatus !== 'Idle')
        props.updateOdometer(Math.abs(speedDirection), thrust, accelerationRate)
  
      rotation = turnHandler(speedDirection, rotation, turnRestrict) 
  
      return { speed: speedDirection, rotation: rotation }
    }
  
    const turnHandler = (getCurrentSpeedDirection, getCurrentRotation, getTurnRestrict) => {
      
      // console.log('joystickAngle2: ', joystickAngle)

      // calculate turning & turn radius on left/right from keyboard inputs
      const currentSpeed = Math.abs(getCurrentSpeedDirection)
      let newRotation = Math.abs(getCurrentRotation)

      if (getTurnRestrict === false && currentSpeed > 1) {
  
        // TURN RIGHT < 0
        if ((turnRight || (joystickAngle > 90 && joystickAngle < 180)) && currentSpeed > 0) {

          if (getCurrentSpeedDirection < 0) {

            // prevents quick turns from jumping to the opposite end of the turn
            if (getCurrentRotation > 0)
              getCurrentRotation = 0

            if (joystickAngle){
              // mobile control
              const anglePercentage = 1 - Math.round((180 - joystickAngle) / 90 * 1e1) / 1e1
              newRotation = -(turnRadiusMax * anglePercentage)
              // console.log("newRotation", newRotation)
            } else
              newRotation = getCurrentRotation - turnRate

          } else if (getCurrentSpeedDirection > 0) {

            if (getCurrentRotation < 0)
              getCurrentRotation = 0

            newRotation = getCurrentRotation + turnRate
            // console.log('reverse right')
          }
            
        // TURN LEFT > 0
        } else if ((turnLeft || (joystickAngle > 0 && joystickAngle <= 90)) && currentSpeed > 0) {

          if (getCurrentSpeedDirection < 0) {

            if (getCurrentRotation < 0)
              getCurrentRotation = 0

            if (joystickAngle){
              // mobile control
              const anglePercentage = 1 - Math.round((joystickAngle / 90) * 1e1) / 1e1
              newRotation = turnRadiusMax * anglePercentage
              // console.log("newRotation", newRotation)
            } else
              newRotation = getCurrentRotation + turnRate
            // console.log('forward left')

          } else if (getCurrentSpeedDirection > 0) {

            if (getCurrentRotation > 0)
              getCurrentRotation = 0

            newRotation = getCurrentRotation - turnRate
            // console.log('reverse left')
          }

        } else {

          // console.log('not turning, so dampen')

          if (getCurrentRotation > turnReleaseDampen) {
            newRotation = getCurrentRotation - turnReleaseDampen
          } else if (getCurrentRotation < -turnReleaseDampen) {
            newRotation = getCurrentRotation + turnReleaseDampen
          } else if (getCurrentRotation <= turnReleaseDampen && getCurrentRotation >= -turnReleaseDampen) {
            newRotation = 0
          }

        }
  
      }

      // clamp rotation min-max
      newRotation = newRotation <= turnRadiusMax ? 
                    newRotation >= -turnRadiusMax ? 
                    newRotation : -turnRadiusMax : turnRadiusMax

      return newRotation
    }
  
    const moveAction = (key, isPressed, angle) => {

      // keyboard control
      if (key) {
        switch(key.toLowerCase()) {
          case 'w':
            goDirection = isPressed ? "forward" : false
            break
          case 's': 
            goDirection = isPressed ? "reverse" : false
            break
          case 'a': 
            turnLeft = isPressed
            break
          case 'd': 
            turnRight = isPressed
            break
          default:
            break
        }

      // mobile joystick control
      } else if (angle) {

        goDirection = false
        turnLeft = false
        turnRight = false

        if (angle >= -22.5 && angle < 22.5) {
          turnLeft = true
        } else if (angle >= 22.5 && angle < 67.5) {
          turnLeft = true
          goDirection = "forward"
        } else if (angle >= 67.5 && angle < 112.5) {
          goDirection = "forward"
        } else if (angle >= 112.5 && angle < 157.5) {
          turnRight = true
          goDirection = "forward"
        } else if (angle >= 157.5 && angle > -157.5) {
          turnRight = true
        } else if (angle >= -157.5 && angle < -112.5) {
          turnRight = true
          goDirection = "reverse"
        } else if (angle >= -112.5 && angle < -67.5) {
          goDirection = "reverse"
        } else if (angle >= -67.5 && angle < -22.5) {
          turnLeft = true
          goDirection = "reverse"
        }
      } else if(angle === false) {
        goDirection = false
        turnLeft = false
        turnRight = false
      }
    }
  
  
    const getAccelerationSpeed = (getSpeedDirection, getIntentialDirection) => {
  
      // Note: getSpeedDirection is a negative number when moving forward 
      let speed = Math.abs(getSpeedDirection)
  
      // calculated based on current thrust direction
      const momentumDirection = getSpeedDirection < 0 ? 'forward thrust' : 'reverse thrust'
  
      turnRestrict = false
  
      if (getIntentialDirection === 'forward') {
  
        // intentional forward movement
        if (getSpeedDirection <= 0) {
  
          // gradual forward speed increase without going over max
          const newSpeed = speed + accelerationRate
          
          // if cartype changes and maxspeed decreases, gradually slow down
          if (speed <= maxSpeed)
            speed = (newSpeed > maxSpeed ? maxSpeed : newSpeed) * -1
          else
            speed = (speed - decelerationRate) * -1
        
        // intentional forward movement while car has reverse momentum
        } else if (getSpeedDirection > 0) {
          // slow down the car drastically
          turnRestrict = true
          speed -= brakePower
        }
        
      } else if (getIntentialDirection === 'reverse') {
  
        // intentional reverse movement
        if (getSpeedDirection >= 0) {
  
          // gradual reverse speed increase without going over max
          const newSpeed = speed + accelerationRate
          speed = newSpeed > maxReverseSpeed ? maxReverseSpeed : newSpeed
  
        // intentional reverse movement while car has forward momentum
        } else if (getSpeedDirection <= 0) {
          // slow down the car drastically
          // turnRestrict = true
          speed -= brakePower
          speed *= -1
        }
  
      }
  
      speed = Math.round(speed * 1e1) / 1e1 // round to 1 decimals
    
      // Send getSpeedDirection & momentumDirection info to App
      // getSpeedDirection is negative due to the way the camera is facing by default
      // added double negative for debug / ui
  
      return speed
    }
  
  
    const getDecelerationSpeed = (currentSpeedDirection) => {
  
      if ( currentSpeedDirection > decelerationRate ) {
        currentSpeedDirection -= decelerationRate
      } else if ( currentSpeedDirection < -decelerationRate ){
        currentSpeedDirection += decelerationRate
      } else {
        return 0
      }
  
      return Math.round(currentSpeedDirection * 1e1) / 1e1
    }
  
  
    useFrame( e => {
      const movement = getSpeedAndRotation()
      e.camera.translateZ(movement.speed * perceptualSpeedMultiplier)
      e.camera.rotation.y += movement.rotation

      // console.log(e.camera.position)
      // console.log(carType)
      if (e.camera.position.z < -50000 && carType !== 1)
        setCarType(1)
      else if (e.camera.position.z > -50000 && carType !== defaultCarType)
        setCarType(defaultCarType)

      if (isMobile)
        if (joystickActive)
          moveAction(false, false, joystickAngle)
        else
          moveAction(false, false, false)

    })
  
    
    // TODO: Add some up/down camera move on accel/decel for extra realism
    // TODO: Ease in turning especially left max - right max
  
    // BUG: When going forward and then tapping reverse (or vice versa) the movement direction instantly switches
  
    return null
  }

  const joystickBaseHandler = (e) => {
    // console.log(e)
    const jsRef = joystickBaseRef.current
    const jsBaseRef = joystickBaseRef.current
    const jsCenter = {x: jsRef.offsetLeft + jsRef.offsetWidth/2, y: jsRef.offsetTop + jsRef.offsetHeight/2}

    // const jsBaseX = jsBaseRef.offsetLeft
    // const jsBaseY = jsBaseRef.offsetTop

    
    const distanceFromCenterX = jsCenter.y - e.changedTouches[0].clientY
    const distanceFromCenterY = jsCenter.x - e.changedTouches[0].clientX

    if (e.type === 'touchstart') {
      joystickActive = true
    } else if (e.type === 'touchend') {
      joystickActive = false
      joystickAngle = false
      joystickRef.current.style.top = 'unset'
      joystickRef.current.style.left = 'unset'
    }

    if (e.type === 'touchmove') {

      joystickActive = true

      // console.log('jsCenter.x: ', jsCenter.x)
      // console.log('jsCenter.y: ', jsCenter.y)
      // console.log('e.changedTouches[0].clientX: ', e.changedTouches[0].clientX)
      // console.log('e.changedTouches[0].clientY: ', e.changedTouches[0].clientY)

      const distanceFromCenter = Math.sqrt(Math.pow(distanceFromCenterX, 2) + Math.pow(distanceFromCenterY, 2))

      joystickAngle = Math.atan2(distanceFromCenterX, distanceFromCenterY) * 180 / Math.PI

      // console.log("Angle: %d - Distance: %d", joystickAngle, distanceFromCenter)

      var t = 50 / distanceFromCenter
      const joystick_limitX = (1 - t) * e.changedTouches[0].clientX + t * jsCenter.x
      const joystick_limitY = (1 - t) * e.changedTouches[0].clientY + t * jsCenter.y
      // console.log(`${joystick_limitX}, ${joystick_limitY}`)

      const joystickTop = clamp(distanceFromCenterX, -50, 50) * -1
      const joystickLeft = clamp(distanceFromCenterY, -50, 50) * -1
      
      joystickRef.current.style.top = `${joystickTop}px`
      joystickRef.current.style.left = `${joystickLeft}px`

      function clamp(val, min, max) {
        return val < min ? min : val > max ? max : val;
      }
    }
  }

  const Joystick = () => {
    return (
      <div 
        ref={joystickBaseRef} 
        className="joystick-overlay" 
        onTouchMove={joystickBaseHandler} 
        onTouchEnd={joystickBaseHandler} 
      >
        <div ref={joystickRef} className="joystick" />
      </div>
    )
  }

  const updateOdometer = (getSpeed, getThrust, accelerationRate) => {
    speed = getSpeed

    if (cruiseMode) {  
      mphRef.current.querySelector('.text').innerHTML = `${(getSpeed).toFixed(0) } MPH`
      mphRef.current.querySelector('.needle').style.transform = `rotate(${(getSpeed * 1.534) + 50}deg)`

      const rpm = getThrust * 70
      const rpmText = `${(rpm > 70 ? rpm.toFixed(0) : 0) } RPM X 100`
      const rpmRotationAngle = (getThrust * 2.6) + 50
      const rpmNeedleRotation = `rotate(${rpmRotationAngle}deg)`
      rpmRef.current.querySelector('.text').innerHTML = rpmText
      rpmRef.current.querySelector('.needle').style.transform = rpmNeedleRotation
    }
  }

  const Dashboard = () => {

    const [currentAudioTrack, setAudioTrack] = useState(0)
    const audioRef = useRef(null)

    const playlist = {
      1: "MOKKA-Gaming_Retrowave_Synthwave Music.mp3",
      2: "MOKKA-Cinematic_Synthwave.mp3",
      3: "MOKKA-Past_Dreams.mp3",
      4: "MOKKA-Synthetic_Pleasures.mp3"
    }
  
    const changeAudioTrack = e => {

      const audioTrack = e.target.dataset.track
      if (audioTrack === currentAudioTrack) {
        audioRef.current.pause()
        setAudioTrack(0)
      } else {
        audioRef.current.src = `/music/${playlist[e.target.dataset.track]}`
        setAudioTrack(e.target.dataset.track)
        audioRef.current.load()
      }

    }
  
    const audioLoaded = e => {
      audioRef.current.play()
      audioRef.current.volume = 0.2
    }

    const mphArr = [], rpmArr = []

    // generate RPM ticks & numbers w/ rotations
    for (let i = 0; i < 15; i++) {
      rpmArr.push(<li key={i} data-tick={i} style={{transform: `rotate(${i * 18.65 + 50}deg)` }}><span className="number" style={{ transform: `rotate(-${i * 18.65 + 50}deg)`}}>{i % 2 === 0 ? i * 10 / 2: ''}</span></li>)
    }

    // generate MPH ticks & numbers w/ rotations
    for (let i = 0; i < 18; i++) {
      mphArr.push(<li key={i} data-tick={i} style={{transform: `rotate(${i * 15.3 + 51}deg)` }}><span className="number" style={{ transform: `rotate(-${i * 15.3 + 51}deg)`}}>{i % 2 === 1 ? i * 10: ''}</span></li>)
    }

    return <section className="dashboard">
      <section className="odometer">

        <div className="dials rpm" ref={rpmRef}>
          <span className="text">0 RPM X 100</span>
          <span className="needle" />
          <ul>
            {rpmArr}
          </ul>
        </div>
        <div className="dials mph" ref={mphRef}>
          <span className="text">0 MPH</span>
          <span className="needle" />
          <ul>
            {mphArr}
          </ul>
        </div>
      </section>

      <section className="radio" ref={radioRef} data-current-track={currentAudioTrack}>
        <button className="dials volume"></button>
        <button data-track="1" onClick={changeAudioTrack}>88</button>
        <button data-track="2" onClick={changeAudioTrack}>92</button>
        <button data-track="3" onClick={changeAudioTrack}>100</button>
        <button data-track="4" onClick={changeAudioTrack}>108</button>
        <button className="dials tuner"></button>
      </section>
  
      <audio ref={audioRef} loop src="" type="audio/mpeg" onLoadedData={audioLoaded}>
        Your browser does not support the HTML5 Audio element.
      </audio>

    </section>
  }


  return (
    <>
      { !cruiseMode && 
        <SplashOverlay cruiseModeHandler={cruiseModeHandler} />
      ||
        <>
          <Dashboard />
          { isMobile && <Joystick /> || <KeyboardOverlay showControls={showControls} /> }
        </>
      }

      <Canvas shadowMap style={{zIndex: -1}}>
        <Camera />
        <CameraControl 
          updateOdometer={updateOdometer}
          getPressedKey={getPressedKey}
          cruiseMode={cruiseMode} 
          joystickActive={joystickActive}
          joystickAngle={joystickAngle} />
        <ambientLight intensity={0.2} color="yellow" />
        {/* <directionalLight color="white" intensity="4" /> */}
        {/* <gridHelper args={[320000, 750]} /> */}
        <Lights brightness={100} color={"pink"} />
        {/* <Lights /> */}
        {/* <fog attach="fog" args={["#fff", 500, 1000]} /> */}
        <Objects />
        <Suspense fallback={null}>
          <Buildings />
        </Suspense>
        <Effects getSpeedHandler={getSpeedHandler} />
      </Canvas>
    </>
  )
}
