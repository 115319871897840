import React, { useRef, useEffect } from 'react'

import { useThree, useFrame, extend } from 'react-three-fiber'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
// import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'
// import { FilmPass } from 'three/examples/jsm/postprocessing/FilmPass'

extend({ EffectComposer, RenderPass, UnrealBloomPass })

export default function Effects(props) {
  const composer = useRef()
  const bloom = useRef()
  const { scene, gl, size, camera } = useThree()
  useEffect(() => void composer.current.setSize(size.width, size.height), [size])
  useFrame(() => {
    composer.current.render()

    // update bloom strength based on speed. Faster == Moar Bloom!!
    const speed = props.getSpeedHandler()
    let bloomStrength
    const bloomMin = 0.8
    const bloomMax = 1.4 // theoretical max is 3

    if (speed > 50) {
      let newBloom = (bloomMax/50) * (speed - 50)
      newBloom = newBloom < bloomMin ? bloomMin : newBloom
      bloomStrength = Math.round(newBloom * 1e1) / 1e1
    } else {
      bloomStrength = 0.8
    }

    bloom.current.strength = bloomStrength

  }, 2)

  return (
    <effectComposer ref={composer} args={[gl]}>
      <renderPass attachArray="passes" scene={scene} camera={camera} />
      <unrealBloomPass ref={bloom} attachArray="passes" args={[undefined, 0, 0.1, 0.3]} />
    </effectComposer>
  )
}