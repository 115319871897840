export default function SplashOverlay(props) {

  return (
    <div className="overlay">

      <h1>
        <span style={{fontSize: '2em'}}>V</span>
        <span style={{top: '-19px', fontSize: '1.3em'}}>A</span>
        <span style={{top: '-23px', fontSize: '1.3em'}}>P</span>
        <span style={{top: '-30px', fontSize: '1.3em'}}>O</span>
        <span style={{top: '-39px', fontSize: '1.3em'}}>R</span>
        <span style={{top: '-39px', fontSize: '2em'}}>D</span>
        <span style={{top: '-45px', fontSize: '1.5em'}}>R</span>
        <span style={{top: '-36px', fontSize: '1.5em'}}>I</span>
        <span style={{top: '-36px', fontSize: '1.6em'}}>V</span>
        <span style={{top: '-36px', fontSize: '1.7em'}}>E</span>
      </h1>

      <div className="button-group">
        <button className="button button-drive" onClick={props.cruiseModeHandler}>
          <span>LET'S DRIVE</span>
        </button>
      </div>

    </div>
  )
}