import { useLoader } from 'react-three-fiber'
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
// import buildings from './../models/buildings.glb'
import buildings2 from './../models/buildings-2.glb'

export default function Buildings() {
  
  const gltf = useLoader(GLTFLoader, buildings2)
  // console.log('gltf: ', gltf)

  const City = () => {
    return (
      <mesh>
        <bufferGeometry 
          attach="geometry" {...gltf.nodes.City.geometry} />
        <meshStandardMaterial 
          attach="material" {...gltf.materials["Default Material"]}
          color="#000" 
          metalness={0} 
          roughness={1}
          emissive="#3e0039" />
      </mesh>
    )
  }

  const CitySpires = () => {
    return (
      <>
        <mesh>
          <bufferGeometry 
            attach="geometry" {...gltf.nodes.CitySpires.children[0].geometry} />
          <meshStandardMaterial 
            attach="material" {...gltf.materials.DefaultMaterial}
            color="#000" 
            metalness={0} 
            roughness={1}
            emissive="#3e0039" />
        </mesh>
        <mesh>
          <bufferGeometry 
            attach="geometry" {...gltf.nodes.CitySpires.children[1].geometry} />
          <meshStandardMaterial 
            attach="material" {...gltf.materials["BuildingGlow"]}
            color="#F7FD40" 
            metalness={0}
            emissive="#F7FD40" />
        </mesh>
      </>
    )
  }

  const CityBottle = () => {
    return (
      <>
        <mesh>
          <bufferGeometry 
            attach="geometry" {...gltf.nodes.CityBottle.children[0].geometry} />
          <meshStandardMaterial 
            attach="material" {...gltf.materials.DefaultMaterial}
            color="#000" 
            metalness={0} 
            roughness={1}
            emissive="#3e0039" />
        </mesh>
        <mesh>
          <bufferGeometry 
            attach="geometry" {...gltf.nodes.CityBottle.children[1].geometry} />
          <meshStandardMaterial 
            attach="material" {...gltf.materials["DefaultMaterial"]}
            color="#F7FD40" 
            metalness={0}
            emissive="#F7FD40" />
        </mesh>
        <mesh>
          <bufferGeometry 
            attach="geometry" {...gltf.nodes.CityBottle.children[1].geometry} />
          <meshStandardMaterial 
            attach="material" {...gltf.materials["WindowGlow"]}
            color="#F7FD40" 
            metalness={0}
            emissive="#F7FD40" />
        </mesh>
        <mesh>
          <bufferGeometry 
            attach="geometry" {...gltf.nodes.CityBottle.children[2].geometry} />
          <meshStandardMaterial 
            attach="material" {...gltf.materials["SlantGlow"]}
            color="#000" 
            metalness={0}
            emissive="#aaa" />
        </mesh>
      </>
    )
  }

  const Railway = (props) => {
    // console.log("props.position: ", props.position)
    return (
      <>
        <mesh position={props.position} rotation={props.rotation}>
          <bufferGeometry 
            attach="geometry" {...gltf.nodes.Railway.children[0].geometry} />
          <meshStandardMaterial 
            attach="material" {...gltf.materials["RailwayMaterial"]}
            color="#000" 
            metalness={0} 
            roughness={1}
            emissive="#3e0039" />
        </mesh>
        <mesh position={props.position} rotation={props.rotation}>
          <bufferGeometry 
            attach="geometry" {...gltf.nodes.Railway.children[1].geometry} />
          <meshStandardMaterial 
            attach="material" {...gltf.materials["RailwayLights"]}
            color="#FF50F1" 
            metalness={0} 
            emissive="#FF89F6" />
        </mesh>
      </>
    )
  }

  const Signs = () => {
    return (
      <mesh position={[0.2, 0, 0]} >
        <bufferGeometry 
          attach="geometry" {...gltf.nodes.Signs.geometry} />
        <meshStandardMaterial 
          attach="material" {...gltf.materials.NeonPink}
          color="#000" 
          metalness={0}
          emissive="#fff" />
      </mesh>
    )
  }

  const Road = () => {
    return (
      <>
        <mesh position={[0, 0, 0]} >
          <bufferGeometry 
            attach="geometry" {...gltf.nodes.Road.children[0].geometry} />
          <meshStandardMaterial 
            attach="material" {...gltf.materials.DefaultMaterial}
            color="#000" 
            metalness={0} 
            roughness={1}
            emissive="#2A0127" />
        </mesh>
        <mesh position={[0, 0, 0]} >
          <bufferGeometry 
            attach="geometry" {...gltf.nodes.Road.children[1].geometry} />
          <meshStandardMaterial 
            attach="material" {...gltf.materials.RoadLightas}
            color="#008682" 
            metalness={0}
            emissive="#00DBD4" />
        </mesh>
      </>
    )
  }

  return (
    <>
      <group scale={[800,800,800]} position={[0, -2, 0]}>
        <City />
        <CitySpires />
        <CityBottle />
        <Signs />
        <Road />
        <Railway position={[0, 0, 0]}  />
        <Railway position={[-62, 0, 0]} />
        <Railway position={[0, 2, -9]} rotation={[0, 1.56, 0]} />
        <Railway position={[0, 2, 69.3]} rotation={[0, 1.56, 0]} />
      </group>
      
    </>
  )
}