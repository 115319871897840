import React, { useRef } from 'react'
import { useFrame } from 'react-three-fiber'

export default function Objects() {

  let Objects = []
  const sphere = useRef()
  const cubeRef = useRef()
  let direction = 'left'
  
  useFrame(()=>{
    if (sphere.current) {
      sphere.current.rotation.y += 0.01
      sphere.current.rotation.x += 0.02
      if (direction === 'left')
        sphere.current.position.x -= 1
      else
        sphere.current.position.x += 1

      if (sphere.current.position.x > 3000)
        direction = 'left'
      else if (sphere.current.position.x < -3000)
        direction = 'right'
    }
  })

  const Sun = (
    <mesh position={[0, -10, -160000]}>
      <circleGeometry args={[1500,32,0,3.14159]} />
      <meshBasicMaterial color="#ffff00" />
    </mesh>
  )

  // Objects.push(Sun)

  const TriObject = (
    <mesh 
      ref={sphere}
      visible
      userData={{ test: "hello" }}
      position={[0, 15000, -20000]}
      rotation={[0, 0, 0]}
      scale={[15,15,15]}
      castShadow
      receiveShadow
    >
      <sphereGeometry 
        attach="geometry" 
        args={[120,30,30]} />
      <meshStandardMaterial
        attach="material"
        color="red"
        roughness={0}
        metalness={0.5} 
        emissive="#999"
      />
    </mesh>
  )

  Objects.push(TriObject)

  const cube = (x,y,z,l,h,w,color) => {
    return (
      <mesh position={[x, y, z]} ref={cubeRef}>
        <boxGeometry args={[l, h, w]} />
        <meshStandardMaterial color={color} emissive={color} />
      </mesh>
    )
  }

  let offset = 0, offsetDirection = 1
  for(let z = 0; z < 555; z++) {

    // zig zag road
    if (z % 30 === 0)
      offsetDirection *= -1
      
    if (offsetDirection === -1)
      offset -= 150
    else
      offset += 150

    for (let x = 1; x < 2; x++) {
      const color = `#${randomColorValue()}`
      Objects.push(cube(x * -1000 + offset, 50, (-z * 1000) + -51500, 50, 250, 1050, color))
      Objects.push(cube(x * 1000 + offset, 50, (-z * 1000) + -51500, 50, 250, 1050, color))
      Objects.push(cube(x + offset, 500, (-z * 1000) -51500, 250, 50, 1050, color))
      Objects.push(cube(x + offset, -500, (-z * 1000) -51500, 250, 50, 1050, color))
    }
  }

  function randomColorValue() {
    let color = ''
    while(color.length < 6) {
      const hex = (Math.floor((Math.random() * 255) + 1)).toString(16).padStart(2, '0')
      color += hex
    }
    return color
  }

  
  // const Ground = (
  //   <mesh receiveShadow rotation={[-1.57,0,0]}>
  //     <planeBufferGeometry attach="geometry" args={[5500,5500]} />
  //     <meshStandardMaterial attach="material" color="pink" />
  //   </mesh>
  // )
  // Objects.push(Ground)
  
  return [...Objects]
}