import { useEffect, useState } from 'react'

export default function DeviceDetect() {

  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent

    const mobileAgent = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    )
    setIsMobile(mobileAgent)
  }, [])

  return isMobile
}