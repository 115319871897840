import React, { useRef } from 'react'

export default function Lights({brightness, color}) {

  const rectLight = useRef()

  return (
    <>
      {/* <rectAreaLight ref={rectLight}
        width={1000}
        height={1000}
        color='#fff'
        intensity={2}
        // position={[0, 100, 0]}
        lookAt={[0,0,0]}
        penumbra={0.5}
        // castShadow 
      /> */}

      <spotLight 
        color="#ddd" 
        position={[0, 30000, 0]}
        lookAt={[0,0,0]}
        intensity={4}
        penumbra={2}
        // castShadow
      />

      {/* <ambientLight
        color="#fff"
        intensity={1}
      /> */}

      <directionalLight intensity={2} color="#888" castShadow  />

      {/* <pointLight position={[0, 3000, 0]} intensity={0} /> */}

    </>
  )
}