import React, { useRef, useEffect } from 'react'
import { useThree, useFrame } from 'react-three-fiber'

function CameraControl(props) {

  let goDirection = false,
      turnRight = false,
      turnLeft = false,
      turnRestrict = false,
      thrustDirection = 0.0, 
      rotation = 0,
      lastDirection = false

  const ACCELERATION_RATE = 0.3,
        BRAKE_POWER = 1.5,
        TURN_RADIUS_MAX = 0.03,
        TURN_RADIUS_MIN = 0.01,
        MAX_SPEED = 100,
        MAX_REVERSE_SPEED = 15

  // Listeners for Keyboard WASD Controls
  document.addEventListener('keydown', function(e){
    if (props.cruiseMode) {
      moveAction(e.key, true)
      props.getPressedKey(e.key, true)
    }
  })

  document.addEventListener('keyup', function(e){
    if (props.cruiseMode) {
      moveAction(e.key, false)
      props.getPressedKey(e.key, false)
    }  
  })
  


  const getSpeedAndRotation = () => {

    /* **************************************
    Event-Loop function that calculates speed 
    (accel/decel) based off keyboard input listeners
    *****************************************/

    let thrustStatus

    // if intentially accelerating/decelerating
    if (goDirection) {

      lastDirection = goDirection
      thrustDirection = getAccelerationSpeed(thrustDirection, goDirection)
      thrustStatus = 'accelerating'

    // automatic deceleration
    } else {
    
      thrustDirection = getDecelerationSpeed(thrustDirection)
      if (thrustDirection === 0) rotation = 0
      thrustStatus = 'decelerating'

    } 

    // update odometer
    const momentumStatus = (thrustDirection && `${thrustStatus} ${lastDirection}`) || 'Idle'
    props.getSpeedHandler(Math.abs(thrustDirection), 0, momentumStatus)


    rotation = turnHandler(thrustDirection, rotation, turnRestrict) 

    return { speed: thrustDirection, rotation: rotation }
  }

  const turnHandler = (getCurrentThrust, getCurrentRotation, getTurnRestrict) => {
    
    // calculate turning & turn radius on left/right from keyboard inputs
    let turnRadius = TURN_RADIUS_MAX
    let radius = turnRadius > TURN_RADIUS_MIN ? turnRadius : TURN_RADIUS_MAX
    getCurrentThrust = Math.abs(getCurrentThrust)

    if (getTurnRestrict === false && getCurrentThrust > 1) {

      if (turnRight && getCurrentThrust > 0) {
        if (lastDirection === 'forward')
          getCurrentRotation = radius * -1
        else if (lastDirection === 'reverse')
          getCurrentRotation = radius
      } else if (turnLeft && getCurrentThrust > 0) {
        if (lastDirection === 'forward')
          getCurrentRotation = radius
        else if (lastDirection === 'reverse')
          getCurrentRotation = radius * -1
      } else {
        getCurrentRotation = 0
      }

    }

    return getCurrentRotation
  }

  const moveAction = (key, isPressed) => {
    switch(key.toLowerCase()) {
      case 'w':
        goDirection = isPressed ? "forward" : false
        break
      case 's': 
        goDirection = isPressed ? "reverse" : false
        break
      case 'a': 
        turnLeft = isPressed
        break
      case 'd': 
        turnRight = isPressed
        break
      default:
        break
    }
  }


  const getAccelerationSpeed = (getThrustDirection, getIntentialDirection) => {

    // Note: getThrustDirection is a negative number when moving forward 
    let speed = Math.abs(getThrustDirection)

    // calculated based on current thrust direction
    const momentumDirection = getThrustDirection < 0 ? 'forward thrust' : 'reverse thrust'

    turnRestrict = false

    if (getIntentialDirection === 'forward') {

      // intentional forward movement
      if (getThrustDirection <= 0) {

        // gradual forward speed increase without going over max
        const newSpeed = speed + ACCELERATION_RATE
        speed = (newSpeed > MAX_SPEED ? MAX_SPEED : newSpeed) * -1
      
      // intentional forward movement while car has reverse momentum
      } else if (getThrustDirection > 0) {
        // slow down the car drastically
        turnRestrict = true
        speed -= BRAKE_POWER
      }
      
    } else if (getIntentialDirection === 'reverse') {

      // intentional reverse movement
      if (getThrustDirection >= 0) {

        // gradual reverse speed increase without going over max
        const newSpeed = speed + ACCELERATION_RATE
        speed = newSpeed > MAX_REVERSE_SPEED ? MAX_REVERSE_SPEED : newSpeed

      // intentional reverse movement while car has forward momentum
      } else if (getThrustDirection <= 0) {
        // slow down the car drastically
        turnRestrict = true
        speed -= BRAKE_POWER
        speed *= -1
      }

    }

    speed = Math.round(speed * 1e1) / 1e1 // round to 1 decimals
  
    // Send getThrustDirection & momentumDirection info to App
    // getThrustDirection is negative due to the way the camera is facing by default
    // added double negative for debug / ui

    props.getSpeedHandler(Math.abs(speed), getThrustDirection, momentumDirection)

    return speed
  }


  const getDecelerationSpeed = (getCurrentThrust) => {

    if ( getCurrentThrust > ACCELERATION_RATE ) {
      getCurrentThrust -= ACCELERATION_RATE
    } else if ( getCurrentThrust < -ACCELERATION_RATE ){
      getCurrentThrust += ACCELERATION_RATE
    } else {
      return 0
    }

    return Math.round(getCurrentThrust * 1e1) / 1e1
  }


  useFrame( e => {
    const movement = getSpeedAndRotation()
    e.camera.translateZ(movement.speed)
    e.camera.rotation.y += movement.rotation

    console.log("joystickAngle: ", props.joystickDirection)
  })

  
  // TODO: Add some up/down camera move on accel/decel for extra realism
  // TODO: Ease in turning especially left max - right max

  // BUG: When going forward and then tapping reverse (or vice versa) the movement direction instantly switches

  return null
}

// Camera Setup
function Camera() {

  const camera = useRef()
  const { aspect, setDefaultCamera } = useThree()

  useEffect(() => void setDefaultCamera(camera.current), [setDefaultCamera])

  return <perspectiveCamera 
    ref={camera}
    aspect={aspect}
    position={[0, 150, 1300]}
    // rotation={[0,1.57,0]}
    // rotation={[0,0,0]}
    far={60000}
    onUpdate={self => self.updateProjectionMatrix()}
  />
}

export { CameraControl, Camera } 